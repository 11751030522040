@tailwind base;

/* Temporary solution to prevent horizontal scroll */
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #FF0000;
}
body {
  overflow-x: hidden;
}

@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

@font-face {
  font-family: "Sharp Grotesk Cyr Semibold 21";
  font-weight: 600;
  font-display: swap;
  /* We load fonts from assets.mindvalley.com cdn but you can choose to host the files yourself */
  src: url("https://assets.mindvalley.com/api/v1/assets/270944f2-bb71-4559-b461-aabb0b351d6b.woff2") format("woff2");
}

@font-face {
  font-family: "Sharp Grotesk Cyr Semibold 20";
  font-weight: 600;
  font-display: swap;
  /* We load fonts from assets.mindvalley.com cdn but you can choose to host the files yourself */
  src: url("https://assets.mindvalley.com/api/v1/assets/85092275-d096-483e-a271-7d6094cafca1.woff2") format("woff2");
}

@font-face {
  font-family: "Sharp Grotesk Cyr Medium 22";
  font-weight: 500;
  font-display: swap;
  src: url("https://assets.mindvalley.com/api/v1/assets/b61f86ec-3dbd-4674-907a-f3f26f5101ce.woff2") format("woff2");
}

@font-face {
  font-family: "Sharp Grotesk Cyr Medium 20";
  font-weight: 500;
  font-display: swap;
  src: url("https://assets.mindvalley.com/api/v1/assets/faf3d1e3-d18c-461e-aafa-9e56f9f16ce0.woff2") format("woff2");
}

@font-face {
  font-family: "Sharp Grotesk Cyr Book 19";
  font-weight: 400;
  font-display: swap;
  src: url("https://assets.mindvalley.com/api/v1/assets/0982041e-3874-48dc-bba5-a15c6fb960d1.woff2") format("woff2");
}

@font-face {
  font-family: "Sharp Grotesk Cyr Bold 16";
  font-weight: 700;
  font-display: swap;
  src: url("https://assets.mindvalley.com/api/v1/assets/d0e0425c-3c29-4d67-b4d6-77567bec96ba.woff2") format("woff2");
}

/* should replace this with proper one */
@font-face {
  font-weight: 500;
  font-display: swap;
  font-family: "sharp-medium-22-custom";
  font-style: normal;
  line-height: 19.638px;
  letter-spacing: 0.258px;
  src: url("https://assets.mindvalley.com/api/v1/assets/b61f86ec-3dbd-4674-907a-f3f26f5101ce.woff2") format("woff2");
}

/* should replace this with proper one */
@font-face {
  font-weight: 500;
  font-display: swap;
  font-family: "sharp-medium-21-custom";
  font-size: 10.336px;
  font-style: normal;
  letter-spacing: 0.362px;
  src: url("https://assets.mindvalley.com/api/v1/assets/b61f86ec-3dbd-4674-907a-f3f26f5101ce.woff2") format("woff2");
}

.svg-icon svg path {
  fill: gray;
}

.svg-icon:hover svg path {
  fill: white;
}

.svg-icon-mixer-available svg path {
  fill: #6ddbda !important;
}

.svg-icon-feature-enabled svg path {
  fill: #ff6d5b !important;
}

.svg-icon-volume svg path {
  fill: black !important;
}

/* 
div[role=dialog] {
  z-index: 999999999999999999999999999 !important;
} */

.svg-icon path {
  fill: white;
}



.embla {
  max-width: 100%;
  width: 100%;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 14%;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
} 


@layer utilities {
  .media-controller-fullscreen-hover-transition {
    /* Initial state */
    opacity: 0;
    height: 0;
    overflow: hidden; 

    /* Transition properties */
    transition-property: opacity, height;
    transition-duration: 500ms; 
    transition-timing-function: ease-in-out;
    transition-delay: 2s; 
  }

  /* Group Hover State */
  .group:hover .media-controller-fullscreen-hover-transition  {
    opacity: 1;
    height: 56px; 
    transition-delay: 0s; 
  }


  .media-container-fullscreen-hover-transition {
    /* Initial state */
    opacity: 0;
    max-height: 0;
    overflow: hidden; 

    /* Transition properties */
    transition-property: opacity, max-height;
    transition-duration: 500ms; 
    transition-timing-function: ease-in-out;
    transition-delay: 3s; 
  }

  /* Group Hover State */
  .group:hover .media-container-fullscreen-hover-transition  {
    opacity: 1;
    max-height: 200px;
    transition-delay: 0s;
  }
}


