/* custom Animations */
.nav-dropdown-menu {
  animation: fadeIn 0.5s ease-in-out;
  transform: translateY(0);
}

.slide-in-animation-top-bottom {
  overflow: hidden;

  @media screen and (max-width: 500px) {
    animation: slideInAnimationTopBottom 0.5s ease-in-out;
    height: 100%;
  }
}

.fade-in-animation-left-right {
  opacity: 1;
  animation: fadeInLeftToRight 0.2s ease-in-out;
}

.fade-in-animation-right-left {
  animation: fadeInRightToLeft 0.2s ease-in-out;
  opacity: 1;
}

@keyframes progress-bar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.animate-progress-bar {
  animation: progress-bar 3s linear forwards;
}



.slide-in-animation-right-left-slow {
  animation: slideInRightToLeft 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.slide-out-animation-left {
  animation: slideOutLeftToRight 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.slide-out-left {
  animation: slideOutLeft .3s ease-in-out;
  opacity: 0;
}

.slide-out-right {
  animation: slideOutRight .3s ease-in-out;
  opacity: 0;
}

.slide-in-animation-left-right-slow {
  animation: slideInLeftToRight .3s ease-in-out;
  opacity: 1;
}

.navbar {
  transition: top 0.3s;
  transform: translateY(0);
}

.navbar.hidden {
  animation: slideOutAnimationTop 0.3s ease-in-out;
  transform: translateY(-100px);
}

.navbar.visible {
  animation: slideInAnimationTopBottom 0.3s ease-in-out;
  transform: translateY(0);
}

@keyframes slideInAnimationTopBottom {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutAnimationTop {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes slideInRightToLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  20% {
    opacity: 0.2;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeftToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideInLeftToRight {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInRightToLeft {
  0% {
    transform: translateX(10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInLeftToRight {
  0% {
    transform: translateX(-10px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 1;
  }

}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 1;
  }
}

/* Wave animation */

@keyframes wave {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 300px 0;
  }
}

.playing-wave {
  width: 64px;
  /* margin:2rem auto; */
  position: relative;
  height: 10px;
  background: url('../components/icons/BaseWaveMarquee.svg') repeat-x 0%;
  background-size: contain;

}

.playing-wave-animation {
  animation: 15s wave linear infinite;
}

/* End of custom Animations */



/* background images */
.background-section {
  background: url(../components/backgrounds/BackgroundImage.svg) no-repeat center;
  background-size: cover;
  overflow-x: unset;
}

.card-background {
  background: url(../components/backgrounds/cardBackground.svg) no-repeat center;
  background-size: cover;
  overflow-x: unset;
}

.shape-image {
  background: url(../components/backgrounds/shape.svg) no-repeat center;
  background-size: cover;
  overflow-x: unset;
}

.bg-free-quests {
  background: url(https://res.cloudinary.com/mindvalley/image/upload/v1729490368/workway/Web/free-quest-bg-d.jpg) no-repeat center;
  background-size: cover;
  overflow-x: unset;
}

.end-card {
  background: url(../components/backgrounds/endCard.svg) no-repeat center;
  background-size: cover;
  overflow-x: unset;

  @media screen and (max-width: 768px) {
    background: url(../components/backgrounds/endCard.svg) no-repeat left;
    background-size: none;
  }
}


/* Custom classes */
.disable-click {
  pointer-events: none;
}

.enable-click {
  pointer-events: auto !important;
}

/* Full div video */
.full-screen-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}